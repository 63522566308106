import { render, staticRenderFns } from "./training-feedback.vue?vue&type=template&id=77fa493f&scoped=true&"
import script from "./training-feedback.vue?vue&type=script&lang=js&"
export * from "./training-feedback.vue?vue&type=script&lang=js&"
import style0 from "./training-feedback.vue?vue&type=style&index=0&id=77fa493f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77fa493f",
  null
  
)

export default component.exports