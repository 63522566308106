<template>
  <div class="training-feedback">
    <div class="title">
      <el-input
        placeholder="手机号码快捷查询"
        v-model="input3"
        class="input-with-select"
        @input="search(input3)"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          
        ></el-button>
      </el-input>
    </div>
    <el-card class="box-card">
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="id" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="real_name" label="反馈人" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="content" label="反馈内容" align="center">
        </el-table-column>
        <el-table-column prop="img" label="反馈图片" align="center">
        </el-table-column>
        <el-table-column prop="create_time" label="反馈时间" align="center">
        </el-table-column>
      </el-table>
    </el-card>
    <div class="page">
      <pagination @currentChange="handleCurrentChangeExp" @prevClick='prevClick' @nextClick='nextClick' :startPage='startPage' :total='totalExp'></pagination>
    </div>
  </div>
</template>

<script>
import { feedbackList } from '@/api/exam.js'
export default {
  data () {
    return {
      tableData:[],
      input3:'',
      listQryExp:{},
      totalExp:null,
      startPage:1
    }
  },
  created() {
    this.getList(this.startPage)
  },
  methods:{
    async getList(pages) {
      const res = await feedbackList({page:pages})
      if(res.data.code == 1) {
        this.tableData = res.data.result.list
        this.totalExp = res.data.result.count
      }
    },
    async search (msg) {
      const obj = {
        phone: msg,
        page: 1
      }
      const res = await feedbackList(obj)
      if(res.data.code == 1) {
        this.tableData = res.data.result.list
      }
    },
    handleCurrentChangeExp(size) {
      this.getList(size)
    },
    prevClick(size) {
      this.getList(size)
    },
    nextClick(size) {
      this.getList(size)
    },
  }
}
</script>

<style lang="less" scoped>
.training-feedback {
  padding: 20px 0;
  .title {
    text-align: right;
    padding-bottom: 20px;
    .input-with-select {
      width: 300px;
    }
  }
  .training-zl {
    // padding: 20px 0;
    .box-card {
      .coverImg {
        width: 170px;
        height: 70px;
        border: 1px solid #ccc;
      }
    }
     
    
  }
  .delete {
      color: #f6423b;
    }
  .page{
    margin-top: 20px;
      text-align: right ;
  }
}
</style>
